import PrimaryLogo from '../assets/logo.png'
import isBrowser from 'utility/isBrowser'

const defaultInfo = {
	subdomain: 'signup',
	logo: PrimaryLogo,
	redirect: 'https://app.hometaxshield.com/',
}

let host = isBrowser && window.location.host
let protocol = isBrowser && window.location.protocol
let parts = host && host.split('.')
let _subdomain = ''

// If we get more than 3 parts, then we have a subdomain
if (parts.length >= 3) {
	_subdomain = parts[0]
}

export const origin = isBrowser && window.location && window.location.origin
export const subdomain = _subdomain
export const isSignupDomain = true
export const subdomainInfo = defaultInfo

export default {
	origin,
	subdomain,
	isSignupDomain,
	subdomainInfo,
}
